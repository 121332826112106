import { graphql, useStaticQuery } from "gatsby"

import { QueryFragments } from "./queryFragments" // eslint-disable-line

export const useSiteConfig = () => {
  const { sanitySiteConfig } = useStaticQuery(graphql`
    query {
      sanitySiteConfig {
        footer {
          heading
          handle{
            link
            linkText
          }
          hashtag{
            linkText
            link
          }
          images{
            image{
              ...ImageWithPreview
            }
            url
          }
          copy:_rawCopy(resolveReferences: {maxDepth: 100})
        }
          newsletterPopup{
            buttonText
            placeholder
            copy
            heading
          }
          stockistPopup{
            heading
            copy
          }
          termsPopup{
            heading
            subheading
            copy:_rawCopy(resolveReferences: {maxDepth: 100})
          }
          privacy{
            heading
            copy:_rawCopy(resolveReferences: {maxDepth: 100})
          }
      }
    }
  `)
  return sanitySiteConfig || {}
}
