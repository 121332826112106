import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';

// Hooks
import { useScrollFreeze } from '../../hooks';

const ModalInner = ({ children, color, scroll, ...props }) => {
  useScrollFreeze();
  return (
    <>
      <motion.div
        {...props}
        style={{ background: 'rgba(0, 0, 0, 0.3)' }}
        className={`h-full w-screen top-0 left-0 z-50 fixed overflow-y-scroll flex justify-center ${scroll ? 'items-start' : 'md:items-center'}`}
      >
        {children}
      </motion.div>
    </>
  );
};

export const StandardModal = ({ isOpen, color, children, scroll }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <ModalInner scroll={scroll} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} color={color}>
          {children}
        </ModalInner>
      )}
    </AnimatePresence>
  );
};
